$(document).ready(function(){
    //- debounce function (for scroll event)
    function debounce(a,b,c){var d;return function(){var e=this,f=arguments;clearTimeout(d),d=setTimeout(function(){d=null,c||a.apply(e,f)},b),c&&!d&&a.apply(e,f)}}

    $('body').removeClass('no-js').addClass('js');

    /**
     * [navComponent]
     * @type {Object}
     */
    var navComponent = {
        el: false,
        elYPos : false,
        menu: false,
        sticked: false,
        open: false,

        /**
         * [Init | Stock quelques variables et attach les events listener]
         */
        init: function(){
            this.el = $('#navigationPrimary'); //- Header with .header-1 and .header-navigation
            this.elYPos = $(this.el).height(); //- Y bottom position of Header / this.el
            this.menu = $('#menu'); //- Menu
            this.navicon = $('#navicon'); //- Hamburger button

            this.attachEvent(); //- Attach events to DOM Elements

            //- Specific script if user is on homepage, hash exists and section target exists too, we give active class to nav item
            if (this.isHomePage() && window.location.hash !== '') {
                $(window.location.hash).length ? $('a.nav-item[href="' + window.location.hash  + '"]').addClass('active') : null;
            }
        },

        attachEvent: function(){
            $(this.navicon).on('click', function(e){
                !this.open && this.isMobile() && !$(this.navicon).hasClass('is-open') ? this.showNav() : null;
                this.open && this.isMobile() && $(this.navicon).hasClass('is-open') ? this.hideNav() : null;
                this.open = !this.open;
            }.bind(this));


            var checkSticky = debounce(function(){
                // console.log('scrollDebounce called');
                $(window).scrollTop() > navComponent.elYPos && !navComponent.sticked && !navComponent.isMobile() ? navComponent.setSticky() : null;
                $(window).scrollTop() < (navComponent.elYPos * 2) && navComponent.sticked && !navComponent.isMobile() ? navComponent.setUnSticky() : null;
            }, 25);

            window.addEventListener('scroll', checkSticky);


            //- Specific to Homepage when user click on nav links
            if (this.isHomePage()) {
                $(this.menu).on('click', 'a.nav-item', function(e){
                    e.preventDefault();
                    this.scrollToSection($(e.target).attr('href'), $(e.target));
                }.bind(this));
            }
        },

        setSticky : function(){
            if (!this.sticked && !this.isMobile()) {
                $(this.el).addClass('sticky');
                this.sticked = true;
            }
        },

        setUnSticky: function(){
            if (this.sticked && !this.isMobile()) {
                $(this.el).removeClass('sticky');
                this.sticked = false;
            }
        },


        /** [show | Only Mobile | Show navigation with animation] */
        showNav: function(){
            $(this.navicon).addClass('is-open').attr('aria-expanded','true');
            $(this.menu).removeClass('is-closed').fadeIn(250);
        },

        /** [hide | Only Mobile | Hide navigation with animation] */
        hideNav : function(){
            $(this.navicon).removeClass('is-open').attr('aria-expanded','false');
            $(this.menu).addClass('is-closed').fadeOut(250);
        },

        /** [isMobile | Check if user are on mobile] */
        isMobile: function(){
            return $(window).width() < 1024 ? true : false;
        },

        /** [isOpen | Only mobile | Check if navigation is open ] */
        isOpen: function(){
            return $(this.menu).hasClass('closed');
        },

        /** [isHomePage | @return if user is on homepage] */
        isHomePage: function(){
            return $('.page').hasClass('homepage');
        },

        /**
         * [scrollToSection | Scroll to target section if exist | /!\ Only on Homepage]
         * @param  {[string]} href [link href, `#section`]
         * @param  {[DOM Element[jQuery obj]]} link [nav item clicked]
         */
        scrollToSection: function(href, link){
            var target = $(href);

            //- If target section exists on homepage, else do nothing
            if (target.length) {
                //- If we are on mobile, close nav, else continue
                this.isMobile() ? function(){ this.hideNav(); this.open = false; }.bind(this)() : null;

                //- If is mobile -> nav height is lower than full header height
                var headerHeight = this.isMobile() ? 80 : $(this.el).height();
                $('html, body').animate({
                    scrollTop: (target.offset().top - headerHeight)
                }, 500);

                //- Switch active class to good nav link
                $(this.menu).find('a').removeClass('active');
                link.addClass('active');

            }
        }
    };

    //- Just for debug
    // $('*').each(function(){
    //     $(this).width() > 320 ? console.log($(this)) : null;
    // })

    /**
     * ScrollSpy Component
     */

    scrollSpyComponent = {
        sections : [],
        menu: null,
        links : [],
        currentSection : null,
        lastSection : null,
        win : $(window),

        init : function(){
            //- If we are on Homepage
            if (this.isHomePage()) {

                this.links = $('#menu a.nav-item');
                this.sections = $('section');
                this.menu = $('#menu');
                this.attachEvent();

            }
        },

        attachEvent : function(){
            var checkPos = debounce(function(){
                for (var i = 0; i < scrollSpyComponent.sections.length; i++) {
                    var section = scrollSpyComponent.sections[i];
                    scrollSpyComponent.getWindowPos() > $(section).offset().top ? scrollSpyComponent.currentSection = section : null;
                    scrollSpyComponent.switchCurrentLink();
                }

            }, 25);

            window.addEventListener('scroll', checkPos);
        },

        switchCurrentLink : function(){
            if (this.currentSection !== null && $(this.currentSection).attr('id') != this.lastSection) {
                this.lastSection = $(this.currentSection).attr('id');
                // this.currentLink = this.topbar.find('a[href="#' + this.currentSection.attr('id') + '"]');
                $(this.links).removeClass('active');
                this.menu.find('a[href="#' + $(this.currentSection).attr('id') + '"]').addClass('active');
            }
        },


        /** [isHomePage | @return if user is on homepage] */
        isHomePage: function(){
            return $('.page').hasClass('homepage');
        },

        /** getWindowPos - @return Window Position Y */
        getWindowPos: function(){
            return this.win.scrollTop() + (this.win.height() / 2);
        }

    }

    var historiqueComponent = {
        items: false,
        encarts: false,
        button: false,

        /** Init, select DOM Elements and call attachEvent() - FOR DEMO CLONE DOM ELEMENT */
        init : function(){
            //- If user are on history page
            if (this.isHistoriquePage()) {

                // FOR DEMO - CLONE
                // TODO : REMOVE
                var target = $('.row.row-custom-gutter').first();
                for (var i = 0; i < 60; i++) {
                    $('.col-md-4.col-sm-6').first().clone().appendTo(target);
                    // console.log($('.col-md-4.col-sm-6').first());
                }
                //- END CLONE

                this.encarts = $('.history-item .encart');
                this.button = $('#historyShowButton');

                this.attachEvent();
            }
        },

        /** AttachEvent on click on CTA / Button "Show all events" */
        attachEvent : function(){
            $(this.button).on('click', function(e){
                e.preventDefault();
                this.enterAnimation();
            }.bind(this));
        },

        /** enterAnimation | Animate .encart from display to display block */
        enterAnimation : function(){
            var length = this.encarts.length; //- Stock array length - better performance
            //- Loop on every .history-item .encart
            //- Start at index 8 so the ninth element
            for (var i = 8; i < length; i++) {
                var el = $(this.encarts)[i];
                //- Delay multiplies by index for staggering animation
                $(el).delay(i * 50).fadeIn();
            }
            //- Hide CTA ~ Delay because user see a strange design animation without
            $(this.button).delay(500).fadeOut();
        },

        isHistoriquePage : function(){
            return $('.page').hasClass('historique');
        }
    }

    historiqueComponent.init();

    //- If navigation are present on Document, initialize navComponent && scrollSpyComponent
    if ($('#navigationPrimary').length) {
        navComponent.init();
        scrollSpyComponent.init();
    }


});
